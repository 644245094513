import React from 'react';
import Layout from '../../components/layout';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import ContentCopy from '../../components/content-copy';
import ContentImageXL from '../../components/content-image-xl';
import ContentImageL from '../../components/content-image-l';
import ContentImageM from '../../components/content-image-m';


const GreduDS = ({ data }) => (
  <Layout>
    <section className="columns is-centered case-studies is-gapless is-multiline">
      <div className="column is-12">
        <Img
          className="featured-image"
          fluid={data.imageTwentyFour.childImageSharp.fluid}
          alt="Featured Image"
        />
      </div>
      <div className="column is-12 padding-bottom-0 padding-top-0">
        <div className="columns is-centered">
          <div className="column is-5 is-offset-1">
            <div className="page-title-wrap margin-bot-3">
              <span className="title-decoratiion"></span>
              <h1 className="general-page-title">Gredu Design System Ver 0.1</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="column is-5 padding-top-0">
        <div className="table-wrapper">
          <table className="table is-fullwidth">
            <tbody>
              <tr>
                <td><h2>Client</h2></td>
                <td><p>Gredu - EduTech</p></td>
              </tr>
              <tr>
                <td><h2>Overview</h2></td>
                <td><p>Gredu is an EdTech Startup in Indonesia who provides all systems needed by schools from enrollment, literacy, school management system, and payment. Basically, there are 7 UI Designer and 1 UI/UX Designer (me) that handle more than 6 products that consist of Web Apps and Mobile Apps (Android & iOS). <br /><br /> The lack of guidelines, no versioning rules, inconsistency, slow crafting time, wrong implementation in production, etc. All these issues accumulated and getting worse. So It’s the time to do the heroic moves which is bring everyone in to redesign everything for a faster and healthier design process.</p></td>
              </tr>
              <tr>
                <td><h2>Outcome</h2></td>
                <td><p>Improved overall design quality, fasten the UI crafting phase while teaching juniors simustaneously, get the buy-in for future design system dedicated team. <br /><br />Unified style for android & iOS, color system that accessible based on WCAG standard, initial tone of voice, guidance about how and when to use each components, new collaboration workflow.</p></td>
              </tr>
              <tr>
                <td><h2>My Role</h2></td>
                <td><p>UI/UX Designer, Project Lead.</p></td>
              </tr>
              <tr>
                <td><h2>Timeline</h2></td>
                <td><p>It took roughly 4 months for the this MVP which is cover scalable UI Kit and design guideline for 1 of our mobile app.</p></td>
              </tr>
              <tr>
                <td><h2>Tools</h2></td>
                <td><p>Sketch App, Abstract, Jira, Trello, GatbsyJS+Netlify.</p></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="columns is-centered padding-top-0 is-multiline">
        <div className="column is-12">
          <ContentImageXL>
            <Img
              fluid={data.imageOne.childImageSharp.fluid}
              alt="notes"
            />
          </ContentImageXL>

          <ContentCopy>
            <h2>Research</h2>
            <h3>Understand the real problems </h3>
            <p>Observed how designer and engineer work, how they handle task right from they start designing up to deliver design handoff with its versioning. <br /><br />I did some desk research how other companies at various level handle their design deliverables. I also did heuristic review to existing design.</p><br /><br />
          </ContentCopy>

          <ContentImageXL>
            <Img
              fluid={data.imageEighteen.childImageSharp.fluid}
              alt="notes"
            />
          </ContentImageXL>

          <ContentCopy>
            <h3>Pain Points (Designer Perspectives)</h3>
            <ol type="1">
              <li>Inconsistency</li>
              <li>No guidelines</li>
              <li>Inefficient/slow execution time</li>
              <li>No single source of truth</li>
              <li>No accessibility test</li>
              <li>Poor handoff quality control</li>
              <li>No mentorship in the design team</li>
            </ol>
            <h3>Pain Points (Company Perspectives)</h3>
            <ol type="1">
              <li>Slow product cycle </li>
              <li>Unnecessary high development cost</li>
              <li>Ignorance culture across departments</li>
              <li>Poor user experience means bad brand reputation</li>
            </ol>

            <h3>Challenges</h3>
            <ol type="1">
              <li>Company’s UX maturity is quite low.</li>
              <li>Laid back culture that hesitant to make a high effort breakthrough move.</li>
              <li>Work on this project while still have to finish tasks from PM’s without special  deadline adjustment.</li>
              <li>Each product has its distinguish personality that can not be unified into single style.</li>
            </ol>

            <h3>Set the blueprint for the unified design</h3>
            <p>After conducting some research about the scalable structure and what tools should be used I decided to go with Sketch App & Abstract as the design and collaboration tools. Here is the library chain in Abstract App.</p>
          </ContentCopy>
          <ContentImageM>
            <Img fluid={data.imageFive.childImageSharp.fluid} alt="featured image" />
          </ContentImageM>
          <ContentCopy>
            <p>With modularity in scalability in mind I come with several concepts and end up with this one.</p><br />
            <p>Put every foundational element in separate files to make it easier to maintain. Using a unified style for all products.</p>

            <h3>Evaluate the structure using a dummy project</h3>
            <p>Unfortunately, the previous structure seems not working well since some product’s style can not be merged. For instance, teacher app which has the least feature using bigger type scale and bigger components size when student app has condensed components with smaller type scale.</p>

            <h4>Revised Version</h4>
            <p>These boxes represent a project in Abstract. Every single product uses the centralized foundation that covers:</p>
            <ul>
              <li>Typography</li>
              <li>Color</li>
              <li>Icons</li>
              <li>Illustration</li>
            </ul>
            <p>Here is the revised library chain, using a single design foundation library that called by each product. This way, each product could have its adjusted style while maintaining the core or design foundation that keeps products in line with the company's design language.</p>
          </ContentCopy>
          <ContentImageM>
            <Img fluid={data.imageSix.childImageSharp.fluid} alt="featured image" />
          </ContentImageM>
          <ContentCopy>
            <h2>Get The Buy-in</h2>
            <h3>The hardest part is to get the buy-in from stakeholders</h3>
            <ul>
              <li>Product Managers</li>
              <li>Chief Creative & Operation</li>
              <li>Chief Technology Officer</li>
            </ul>
            <p>I got rejected couple times by the PMs because they don’t really understand the urgency. At this point, I have to work in silent for a while to make the prototype of my solutions.</p>
            <h3>Speak their languages</h3>
            <p>I managed to pitch in front of those stakeholders at our quarterly meetings. Eventually, after showing that benefits overcome the efforts in business perspective I got support from the board directors.</p>
            <p>However, I still have to work on my daily tasks from PMs.</p>

            <h2>MVP</h2>

            <h3>Start with a baby step</h3>
            <p>The team need a design system that sets the guideline for designers, engineers, and writers to work and collaborate faster yet produce high-quality deliverables.</p><br />
            <p>However, it was way too big and unrealistic at that moment to start with all products at the same time</p>
          </ContentCopy>
          <ContentImageM>
            <Img fluid={data.imageSeven.childImageSharp.fluid} alt="featured image" />
          </ContentImageM>
          <ContentCopy>
            <h3>Start with one app with a scalable structure</h3>
            <p>I decided to start with the <strong>student app (iOS & Android)</strong> for the first MVP with a scalable foundation for the rest of the product line up.</p>

            <h3>Implement Design Foundation to Student App</h3>
            <p>As a starting point, I aimed to develop the foundation and refine existing student app components.</p>
            <p><strong>Design Foundation:</strong></p>
            <p>Layer styles, Icons, Illustrations, Typography</p>
            <br />
            <p><strong>Student App Components:</strong></p>
            <p>App bars, Buttons, Chips, Dialogs, Selection Controls, Tabs, etc.</p>
          </ContentCopy>
          <ContentImageM>
            <Img fluid={data.imageEight.childImageSharp.fluid} alt="featured image" />
          </ContentImageM>
          <ContentCopy>
            <h2>The Process</h2>
            <p>Speaking about design foundation, it's a must to understand the current language and what's works and what's not.</p>
            <h3>Collecting, curating, & taking notes</h3>
            <p>Probably, this is the most tedious part, we have dive into every single artboards to collect and sort current assets ranging from icons, illustrations, buttons, forms, type sizes, colors, etc.</p><br />
            <p>Here are some examples of problem in UI perspective:</p><br />
            <p><strong>Icon</strong></p>
            <ul>
              <li>Inconsistent style</li>
              <li>Not using bound</li>
              <li>Redundant icons</li>
            </ul>
            <p><strong>Color Palette</strong></p>
            <ul>
              <li>Redundant color shades</li>
              <li>Color contrast not pass WCAG 2.0 standard</li>
            </ul>
            <p><strong>Illustration</strong></p>
            <ul>
              <li>Not glued into brand identity</li>
            </ul>
            <p><strong>Typography</strong></p>
            <ul>
              <li>There is no standard in type scale</li>
              <li>Contrast issue</li>
            </ul>
            <p><strong>Components</strong></p>
            <ul>
              <li>Inconsistent style</li>
              <li>Accessibility issue</li>
              <li>There is no standard in pattern/behavior (e.g form states)</li>
            </ul>
            <h3>It's time to prepare the scalable sitemap for the deisgn system or guideline pages.</h3>
            <p>Here is the full version of what Design System page that company needs, obviously, it's not gonna be built in the first iteration.</p>
          </ContentCopy>
          <ContentImageL>
            <Img fluid={data.imageNine.childImageSharp.fluid} alt="featured image" />
          </ContentImageL>
          <ContentCopy>
            <h3>Design System Portal Wireframe</h3>
            <p>This is the example of the design system portal once every single product guidelines are developed.</p>
          </ContentCopy>
          <ContentImageM>
            <Img fluid={data.imageTen.childImageSharp.fluid} alt="featured image" />
          </ContentImageM>
          <ContentCopy>
            <h3>Focus on MVP</h3>
            <p>Since the goal at this release is focused on student app, I aimed to deliver UI Kit and design guidelines with scalability in mind.</p>

            <h3>Define the guidelines for cross-platform design</h3>
            <p>Ideally, each platform should get tailored design since they have their own interaction pattern. However, due to the lack of resources, the team decided to go with unified components that fit both Android and iOS app with minimum adjustment as possible.</p>

            <h2>Result</h2>
            <h3>Components in nested symbols</h3>
            <p>Using nested symbol for every single component. With this structure, we just have to make the changes in foundations and respective components then the rest will be updated, this way everything will easier to maintain and make designers can focus on problem-solving and innovation rather than doing tiring repetitive works.</p>
          </ContentCopy>
          <ContentImageM>
            <Img fluid={data.imageEleven.childImageSharp.fluid} alt="featured image" />
          </ContentImageM>
          <ContentCopy>
            <h3>Refined Icons</h3>
            <p>Unified icon style, grouped into relevant categories, and defined icon specs.</p>
          </ContentCopy>
          <ContentImageM>
            <Img fluid={data.imageTwelve.childImageSharp.fluid} alt="featured image" />
          </ContentImageM>
          <ContentCopy>
            <h3>Layer Styles</h3>
            <p>Divided into base, tint, container, and border layer style.</p>
          </ContentCopy>
          <ContentImageM>
            <Img fluid={data.imageThirteen.childImageSharp.fluid} alt="featured image" />
          </ContentImageM>
          <ContentCopy>
            <h3>Refined Components Samples</h3>
            <p><strong>Buttons</strong></p>
            <p>Created new naming system, button sizes, new type of buttons, and defined how and when to use each type.</p>
          </ContentCopy>
          <ContentImageM>
            <Img fluid={data.imageFourteen.childImageSharp.fluid} alt="featured image" />
          </ContentImageM>
          <ContentCopy>
            <p><strong>Input Text</strong></p>
            <p>Provide every possible states of input text.</p>
          </ContentCopy>
          <ContentImageM>
            <Img fluid={data.imageFifteen.childImageSharp.fluid} alt="featured image" />
          </ContentImageM>
          <ContentCopy>
            <p><strong>Structure samples </strong></p>
          </ContentCopy>
          <ContentImageM>
            <Img fluid={data.imageSixteen.childImageSharp.fluid} alt="featured image" />
          </ContentImageM>
          <ContentCopy>
            <h3>Before - After</h3>
            <p>Here are some comparisons between before and after the refinement.</p>
          </ContentCopy>
          <ContentImageM>
            <p className="margin-top-1"><strong>Onboarding screen</strong></p>
            <p className="margin-bot-1">Refined micro copy, button, contrast, and hierarchy.</p>
            <Img fluid={data.imageNineteen.childImageSharp.fluid} alt="featured image" />
          </ContentImageM>
          <ContentImageM>
            <p className="margin-top-3"><strong>Student's performance screen</strong></p>
            <p className="margin-bot-1">Simplified color context and overall accessibility.</p>
            <Img fluid={data.imageTwenty.childImageSharp.fluid} alt="featured image" />
          </ContentImageM>
          <ContentImageM>
            <p className="margin-top-3"><strong>Competency screen</strong></p>
            <p className="margin-bot-1">Improved hierarchy, grouping, and colored text usage</p>
            <Img fluid={data.imageTwentyOne.childImageSharp.fluid} alt="featured image" />
          </ContentImageM>
          <ContentImageM>
            <p className="margin-top-3"><strong>Schedule screen</strong></p>
            <p className="margin-bot-1">Improved hierarchy and overall accessibility</p>
            <Img fluid={data.imageTwentyTwo.childImageSharp.fluid} alt="featured image" />
          </ContentImageM>
          <ContentImageM>
            <p className="margin-top-3"><strong>Calendar screen</strong></p>
            <p className="margin-bot-1">Improved hierarchy and overall accessibility</p>
            <Img fluid={data.imageTwentyThree.childImageSharp.fluid} alt="featured image" />
          </ContentImageM>
          <ContentCopy>
            <h3>Guidelines website for the student app</h3>
            <p>Since our engineers quite hectic at that time, I decided to code the website from scratch by myself. I created the static site using GatsbyJS and hosted it on Netlify.</p>
          </ContentCopy>
          <ContentImageM>
            <Img fluid={data.imageSeventeen.childImageSharp.fluid} alt="featured image" />
          </ContentImageM>
          <ContentCopy>
            <h3>Achievement Summary</h3>
            <ol>
              <li>Created solid baseline for growing design system.</li>
              <li>Set guidelines and conventions (naming, sizes, spacings, etc.).</li>
              <li>Improved overall UI of the app (student app).</li>
              <li>Set new workflow and rules for design revision and handoff.</li>
              <li>Indirectly mentored juniors and nurture collaboration between cross departments.</li>
              <li>Fasten design process (high fidelity).</li>
              <li>Put designers and engineers on the same page (units, naming, versioning, etc.).</li>
            </ol>

            <h2>Project Learnings</h2>
            <ol>
              <li>Show don’t tell, do the extra miles to getting stakeholder taste the benefits</li>
              <li>Speak their languages</li>
              <li>It’s always impossible until it’s done</li>
              <li>Have clarity in WHY</li>
            </ol>
          </ContentCopy>
        </div>
      </div>
      <div className="column is-12">
        <hr />
      </div>

      {/* More Cases Section  */}
      <div className="column is-10">
        <div className="columns is-centered is-multiline">
          <div className="column is-12">
            <h3 className="has-text-centered margin-top-0">More Case Studies</h3>
          </div>
          <div className="column is-4 is-flex">
            <div className="box">
              <div className="columns is-centered">
                <div className="column is-12">
                  <Link to="/case-studies/volantis-design-system">
                    <Img fluid={data.imageTwo.childImageSharp.fluid} alt="featured image" />
                  </Link>
                  <div className="post-card">
                    <Link to="/case-studies/volantis-design-system"><h2 className="read-more-title">Volantis Design System</h2></Link>
                    <Link to="/case-studies/volantis-design-system"><p className="read-more-excerp">The design language that speaks for B2B startup with a modern approach. Covering foundation, UI Kit (for designer & engineer), design assets central.</p></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-4 is-flex">
            <div className="box">
              <div className="columns is-centered">
                <div className="column is-12">
                  <Link to="/case-studies/hris-markplus">
                    <Img fluid={data.imageThree.childImageSharp.fluid} alt="featured image" />
                  </Link>
                  <div className="post-card">
                    <Link to="/case-studies/hris-markplus"><h2 className="read-more-title">HRIS MarkPlus, Inc.</h2></Link>
                    <Link to="/case-studies/hris-markplus"><p>Human resource information system that covers attendance, leave, benefits, employees information, etc.</p></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-4 is-flex">
            <div className="box">
              <div className="columns is-centered">
                <div className="column is-12">
                  <Link to="/case-studies/student-app-course">
                    <Img fluid={data.imageFour.childImageSharp.fluid} alt="featured image" />
                  </Link>
                  <div className="post-card">
                    <Link to="/case-studies/student-app-course"><h2 className="read-more-title">Student App - Course</h2></Link>
                    <Link to="/case-studies/student-app-course"><p>Adding a course platform with a subscription model into an existing student app.</p></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  </Layout >
);

export default GreduDS;


export const query = graphql`
  query GreduDSQuery {
    imageOne:file(relativePath: { eq: "gredu-ds-cover.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 80){
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwo:file(relativePath: { eq: "volantis-ds-page.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageThree:file(relativePath: { eq: "hris-markplus.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageFour:file(relativePath: { eq: "student-app-course-page.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageFive:file(relativePath: { eq: "initial-ds-structure.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageSix:file(relativePath: { eq: "ds-structure.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageSeven:file(relativePath: { eq: "ds-map.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageEight:file(relativePath: { eq: "ds-mvp.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageNine:file(relativePath: { eq: "gredu-ds-sitemap.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTen:file(relativePath: { eq: "portal-gredu-ds.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageEleven:file(relativePath: { eq: "gredu-ds-on-abstract.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwelve:file(relativePath: { eq: "gredu-ds-iconography.png" }) {
      childImageSharp {
        fluid (quality: 80){
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageThirteen:file(relativePath: { eq: "gredu-ds-layer-style.png" }) {
      childImageSharp {
        fluid (quality: 80){
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageFourteen:file(relativePath: { eq: "gredu-ds-buttons.png" }) {
      childImageSharp {
        fluid (quality: 80){
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageFifteen:file(relativePath: { eq: "gredu-ds-inputs.png" }) {
      childImageSharp {
        fluid (quality: 80){
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageSixteen:file(relativePath: { eq: "gredu-ds-naming-system.png" }) {
      childImageSharp {
        fluid (quality: 80){
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageSeventeen:file(relativePath: { eq: "gredu-ds-page-iconography.png" }) {
      childImageSharp {
        fluid (quality: 80){
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageEighteen:file(relativePath: { eq: "gredu-ds-notes.png" }) {
      childImageSharp {
        fluid (quality: 80){
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageNineteen:file(relativePath: { eq: "gredu-ds-onboarding.png" }) {
      childImageSharp {
        fluid (quality: 90){
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwenty:file(relativePath: { eq: "gredu-ds-performa.png" }) {
      childImageSharp {
        fluid (quality: 90){
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwentyOne:file(relativePath: { eq: "gredu-ds-kd.png" }) {
      childImageSharp {
        fluid (quality: 90){
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwentyTwo:file(relativePath: { eq: "gredu-ds-jadwal-ujian.png" }) {
      childImageSharp {
        fluid (quality: 90){
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwentyThree:file(relativePath: { eq: "gredu-ds-kalender.png" }) {
      childImageSharp {
        fluid (quality: 90){
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwentyFour:file(relativePath: { eq: "gredu-ds-hero.png" }) {
      childImageSharp {
        fluid (quality: 90){
          ...GatsbyImageSharpFluid
        }
      }
    }

  }
`
