import React from 'react'
import './style.scss'

const ContentImageL = ({ children }) => (
    <div className="content-image-l">
        {children}
    </div>
)

export default ContentImageL
