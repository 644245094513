import React from 'react'
import './style.scss'

const ContentImageXL = ({ children }) => (
    <div className="content-image-xl">
        {children}
    </div>
)

export default ContentImageXL
